export const HOSTNAME = process.env.REACT_APP_HOSTNAME || "http://localhost:8080";
export const LOCAL_STORAGE_TOKEN_NAME =
    process.env.REACT_APP_LOCAL_STORAGE_TOKEN || "tokelsname";

export const ROUTES = {
    USER_VERIFY: HOSTNAME + "/user/verify",
    USER_SIGN: HOSTNAME + "/user/sign",

    MPU_START: HOSTNAME + "/mpu",
    MPU_COMPLETE: HOSTNAME + "/mpu/complete",

    BROKER_MPU_REQUEST: HOSTNAME + "/broker/mpuRequest",
    BROKER_MPU_STARTED: HOSTNAME + "/broker/mpuStarted",
    BROKER_MPU_COMPLETED: HOSTNAME + "/broker/mpuCompleted",
};

