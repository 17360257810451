import React, { createContext, useEffect, useState } from "react";
import API from "src/api";
import { LOCAL_STORAGE_TOKEN_NAME } from "src/consts";

interface LoginCreds {
    email: string;
    password: string;
}

export const AuthenticationContext = createContext({
    isAuthenticated: false,
    isLoading: false,
    markAsAuthenticated: () => {},
    creds: { email: "", password: "" },
    updateEmail: (i: string) => {},
    updatePassword: (i: string) => {},
    authenticateUser: async () => false,
    logout: () => {},
});

export const AuthenticationProvider = ({ children }: any) => {
    const [creds, setCreds] = useState<LoginCreds>({
        email: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const updateEmail = (newIdentifier: string) => {
        setCreds((pv: LoginCreds) => ({ ...pv, email: newIdentifier }));
    };
    const updatePassword = (newPassword: string) => {
        setCreds((pv: LoginCreds) => ({ ...pv, password: newPassword }));
    };
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const markAsAuthenticated = () => {
        setIsAuthenticated(true);
    };

    useEffect(() => {
        const isCurrentTokenValid = async () => {
            setIsLoading(true);
            const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
            if (!token) {
                localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }
            const isValid = await API.Auth.verifyUser(token);
            if (isValid) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        };
        isCurrentTokenValid();
    }, []);

    const authenticateUser = async (): Promise<boolean> => {
        if (!creds.email || !creds.password) {
            return false;
        }
        setIsLoading(true);
        const response = await API.Auth.signin(creds.email, creds.password);
        if (response.success && response.data) {
            markAsAuthenticated();
            localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, response.data);
            setIsLoading(false);
            return true;
        }
        setIsLoading(false);
        setIsAuthenticated(false);
        return false;
    };

    const logout = () => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
        setIsAuthenticated(false);
    };

    const IState = {
        isAuthenticated,
        logout,
        markAsAuthenticated,
        creds,
        updateEmail,
        updatePassword,
        authenticateUser,
        isLoading,
    };

    return (
        <AuthenticationContext.Provider value={IState}>
            {children}
        </AuthenticationContext.Provider>
    );
};
