import { AxiosResponse } from "axios";
import { ROUTES } from "src/consts";
import { Part } from "src/types";
import { getHeaders, httpClient, isSucessResponse } from "./http-instance";

export const startMPU = async (
    fileName: string,
    parts: number
): Promise<{ success: boolean; data: AxiosResponse }> => {
    try {
        const data = { fileName, parts };
        const response = await httpClient.post(
            ROUTES.MPU_START,
            {
                data,
            },
            getHeaders()
        );
        if (isSucessResponse(response)) {
            return { success: true, data: response };
        } else {
            return { success: false, data: {} as AxiosResponse };
        }
    } catch (error) {
        console.log(error);
        return { success: false, data: {} as AxiosResponse };
    }
};

export const completeMPU = async (
    parts: Part[],
    uploadId: string,
    fileName: string
): Promise<{ success: boolean; data: AxiosResponse }> => {
    try {
        const data = { data: { parts, uploadId, fileName } };
        const response = await httpClient.post(
            ROUTES.MPU_COMPLETE,
            data,
            getHeaders()
        );
        if (isSucessResponse(response)) {
            return { success: true, data: response };
        } else {
            return { success: false, data: {} as AxiosResponse };
        }
    } catch (error) {
        console.log(error);
        return { success: false, data: {} as AxiosResponse };
    }
};
