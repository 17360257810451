import { Button } from "@mui/material";
import { useContext } from "react";
import { AuthenticationContext } from "src/context/auth";
import { useFileUploadContext } from "src/context/fileUpload";

const LogoutButton = () => {
    const { logout } = useContext(AuthenticationContext);
    const { isLoading } = useFileUploadContext();

    return (
        <Button
            style={{
                textTransform: "none",
                gridColumn: 3,
                fontFamily: 'Noto Sans',
                fontWeight: 800,
                color: "#002D74",
                textDecoration: "underline",
                justifySelf: "end",
                paddingRight: "2rem",
            }}
            disabled={isLoading}
            onClick={logout}
        >
            Abmelden
        </Button>
    );
};

export default LogoutButton;
