import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ReactComponent as CheckMarkIcon} from './checkMarkGreen.svg';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, label: string }) {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1"
                    sx={{
                        width: '80%',
                        fontSize: '16px',
                        textAlign: "left",
                        color: "#4C6C9E"
                    }}>
                    {props.label}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        width: '20%',
                        fontSize: '16px',
                        color: '#B8CAE7',
                        textAlign: "right",
                        paddingRight: "10px"
                    }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        sx={{
                            height: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#B8CAE7',
                            '& .MuiLinearProgress-bar': {
                            borderRadius: '10px',
                            backgroundColor: '#002D74',
                            },
                        }}
                    />
                </Box>
            </Box>
        </div>
      );
}

export default function LoadingComponent({progress : progressValue, label: label} : {progress : number, label: string}) {
  const [progressState, setProgress] = React.useState(progressValue);

  React.useEffect(() => {
    // Update the progressState when the prop changes
    setProgress(progressValue);
  }, [progressValue]);

  return (
    <Box sx={{ width: '70%' }}>
      <LinearProgressWithLabel value={progressState} label={truncateString(label, 30)} />
    </Box>
  );
}


function truncateString(str: string, maxLength: number): string {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + '...';
  }
  return str;
}