import Axios, { AxiosResponse } from "axios";
import { createContext, useContext, useState } from "react";
import API from "src/api";
import { MPUSteps, Part } from "src/types";

export interface FileUploadContextState {
    handleDrop: (file: File) => Promise<boolean>;
    clearState: any;
    snackbarOpen: any;
    setSnackbarOpen: any;
    currentStep: any;
    setCurrentStep: any;
    numberOfCompletedParts: any;
    setNumberOfCompletedParts: any;
    totalPartsNumber: any;
    setTotalPartsNumber: any;
    fileData: any;
    setFileData: any;
    isLoading: any;
    setIsLoading: any;
}

const FileUploadContext = createContext({} as FileUploadContextState);
//
// 100000(Byte) * 100 = 1(MB)
const FILE_CHUNK_SIZE = 10_000_000;

export const useFileUploadContext = () =>
    useContext(FileUploadContext);

export const FileUploadProvider = ({ children }: any) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(
        MPUSteps.InitiatedByClient
    );
    const [numberOfCompletedParts, setNumberOfCompletedParts] =
        useState(0);
    const [totalPartsNumber, setTotalPartsNumber] = useState(1);
    const [fileData, setFileData] = useState({
        partsNumber: 0,
        fileSize: 0,
        fileName: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const clearState = () => {
        setCurrentStep(MPUSteps.InitiatedByClient);
        setNumberOfCompletedParts(0);
        setTotalPartsNumber(1);
        setSnackbarOpen(true);
        setFileData({
            partsNumber: 0,
            fileSize: 0,
            fileName: "",
        });
        setIsLoading(false);
    };

    const uploadParts = async (
        file: File,
        urls: Record<number, string>
    ): Promise<{ ETag: any; PartNumber: number }[]> => {
        const axios = Axios.create();
        delete axios.defaults.headers.put["Content-Type"];
        delete axios.defaults.headers.common["authorization"];

        const keys = Object.keys(urls);
        const promises = [];
        let counter = 0;
        for (const indexStr of keys) {
            const index = parseInt(indexStr);
            const start = index * FILE_CHUNK_SIZE;
            const end = (index + 1) * FILE_CHUNK_SIZE;
            const blob =
                index < keys.length
                    ? file.slice(start, end)
                    : file.slice(start);
            promises.push(
                axios
                    .put(urls[index], blob)
                    .then((r: AxiosResponse) => {
                        setNumberOfCompletedParts(counter++);
                        return r;
                    })
            );
        }

        const resParts = await Promise.all(promises);
        return resParts.map(
            (part, index): Part => ({
                ETag: (part as any).headers.etag,
                PartNumber: index + 1,
            })
        );
    };

    const handleDrop = async (files: File[]): Promise<boolean> => {
        try {
            setIsLoading(true);
            // reset file data before starting new upload

            const allUploadedPartsPromises: Promise<boolean>[] = [];

            for (const file of files) {
                setFileData({
                    partsNumber: 0,
                    fileSize: 0,
                    fileName: "",
                });

                const fileSize = file.size;
                const fileName = file.name;
                const partsNumber = Math.ceil(fileSize / FILE_CHUNK_SIZE);
    
                setFileData((pv: any) => ({
                    ...pv,
                    partsNumber,
                    fileName,
                    fileSize,
                }));

                setCurrentStep(MPUSteps.RequestedMPU);
                setTotalPartsNumber(partsNumber);
                await API.Broker.brokerMPURequest(fileName); // logs
                const response = await API.MPU.startMPU(
                    fileName,
                    partsNumber
                );

                if (response.success) {
                    await API.Broker.brokerMPUStarted(fileName); // logs
                    const { pre_signed_urls: preSignedUrls, uploadId } =
                        response.data.data;

                    setCurrentStep(MPUSteps.UploadParts);
                    const uploadedPartsPromise = uploadParts(file, preSignedUrls).then((uploadedParts) => {
                        return API.MPU.completeMPU(uploadedParts, uploadId, fileName);
                    });
                    
                    allUploadedPartsPromises.push(uploadedPartsPromise);
                }
            }

            // Use Promise.all to wait for all uploaded parts to be completed
            const allUploadedPartsResponses = await Promise.all(allUploadedPartsPromises);
            const allSuccessful = allUploadedPartsResponses.every((response) => response);


            if (allSuccessful) {
                // Only set the step to CompleteMPU if all parts were successfully uploaded
                setCurrentStep(MPUSteps.CompleteMPU);

                await API.Broker.brokerMPUCompleted(files.map(file => file.name)); // logs
                clearState();
                return true;
            }
            
            setIsLoading(false);
            return false;

        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
            return false;
        }
    };

    const state: FileUploadContextState = {
        clearState,
        handleDrop,
        snackbarOpen,
        setSnackbarOpen,
        currentStep,
        setCurrentStep,
        numberOfCompletedParts,
        setNumberOfCompletedParts,
        totalPartsNumber,
        setTotalPartsNumber,
        fileData,
        setFileData,
        isLoading,
        setIsLoading,
    };

    return (
        <FileUploadContext.Provider value={state}>
            {children}
        </FileUploadContext.Provider>
    );
};
