import {
    DropzoneInputProps,
    DropzoneRootProps,
} from "react-dropzone";
import LoadingComponent from "src/components/Loading";
import { useFileUploadContext } from "src/context/fileUpload";
import uploadImage from './drag-drop.png';
import { MPUSteps } from "src/types";

const FileInput = ({
    getRootProps,
    getInputProps,
}: {
    getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}) => {
    const {
        currentStep,
        numberOfCompletedParts,
        totalPartsNumber,
        fileData,
        isLoading
    } = useFileUploadContext();

    return (
        <div
            {...getRootProps()}
            style={{
                gridColumn: 2,
                cursor: !isLoading ? "pointer" : "default",
                textAlign: "center",
            }}
        >
            <div
        style={{
            margin: "auto",  
          width: '500px',
          height: '500px',
          borderRadius: '50%',
          background: '#F5F5F5',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {
        isLoading ? (
          <LoadingComponent progress={Math.min(Math.max(numberOfCompletedParts/totalPartsNumber * 100, 0), 100)} label={fileData.fileName}/> // Replace with your loading component
        ) : (
            <div>
                <img src={uploadImage}/>
            </div>
        )}
        <span style={{
            position: 'absolute',
            bottom: '80px',
            width: "100%",
            fontSize: '16px',
            color: "#002D74CC",}}>
          {
            currentStep == MPUSteps.CompleteMPU
            ? <div>Upload erfolgreich! Bitte keine <br/>weiteren Dateien hochladen, bis die <br/>Mail mit Downloadlink kommt.</div>:
            isLoading ? 
            <div>Upload läuft, bitte warten...</div> : 
            <div>Dateien mit <b>Drag & Drop</b><br/>hier ablegen</div>
          }
        </span>
      </div>

            <input {...getInputProps()} disabled={isLoading} />
        </div>
    );
};

export default FileInput;
