export interface Part {
    ETag: string;
    PartNumber: number;
}


export enum ModalType {
    Instructions,
    WrongCrds,
}

export enum MPUSteps {
    InitiatedByClient,
    RequestedMPU,
    UploadParts,
    CompleteMPU,
}

