import { Snackbar } from "@mui/material";
import Alert from "src/components/Alert";
import { useFileUploadContext } from "src/context/fileUpload";

const PageNotification = () => {
    const { snackbarOpen, setSnackbarOpen } = useFileUploadContext();

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <Snackbar
            open={snackbarOpen}
            onClose={handleClose}
            message="Note archived"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
        >
            <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
            >
                Upload erfolgreich! Bitte keine weiteren Dateien
                hochladen, bis die Mail mit Download link kommt.
            </Alert>
        </Snackbar>
    );
};

export default PageNotification;
