import * as MPU from "./mpu";
import * as Broker from "./broker";
import * as Auth from "./auth";

const API = {
    MPU,
    Broker,
    Auth
}

export default API;
