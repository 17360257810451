const PageTitle = ({ text }: { text: string }) => {
    return (
        <h2
            style={{
                color: "#002D74",
                gridColumn: 2,
                cursor: "default",
                justifySelf: "center",
                margin: "auto"
            }}
        >
            {text}
        </h2>
    );
};

export default PageTitle;

