import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFileUploadContext } from "src/context/fileUpload";
import {
    FileInput,
    LogoutButton,
    PageInstructions,
    PageNotification,
    PageTitle,
    Progress,
} from "./components";

const FileUpload = () => {
    const [files, setFiles] = useState<File[]>([]);
    const { handleDrop, isLoading } = useFileUploadContext();

    const onDrop = (acceptedFiles: File[]) => {
        if (files?.length === 2 || acceptedFiles?.length > 2) {
            window.alert("you cant upload more than two files");
            return;
        }
        const currentFileNames = files.map((f) => f.name);
        console.log("currentFileNames", currentFileNames);
        const isDuplicate = Boolean(acceptedFiles.filter((f) => currentFileNames.indexOf(f.name) > -1).length);
        if(isDuplicate){
            window.alert("a file with the same name is present");
            return;

        }
        setFiles([...files, ...acceptedFiles]);
    };

    const uploadMultipleFiles = async () => {
        if (files.length !== 2) {
            window.alert("Please select exactly two files.");
            return false;
        }
    
        const response = await handleDrop(files); // Pass both files together
        return response;
    };

    useEffect(() => {
        if (files.length === 2) {
            const handler = async () => {
                await uploadMultipleFiles();
                setFiles([]);
            };
            handler();
        }
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const removeFileFromList = async (i: File) => {
        const newArray = files.filter((file) => i.name !== file.name);
        setFiles(newArray);
    };

    return (
        <>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    height: "100px"
                }}
            >
                <PageTitle text="Punktwolken hochladen"/>
                <LogoutButton />
            </div>
            <div
                style={{
                    fontSize: "20px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                }}
            >
                <FileInput
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                />
                <div
                    style={{
                        paddingTop: "4rem",
                        gridColumn: 2,
                        textAlign: "center",
                    }}
                >
                    <PageInstructions />
                </div>
            </div>
            <PageNotification />
        </>
    );
};

export default FileUpload;
