import { AxiosResponse } from "axios";
import { ROUTES } from "src/consts";
import { getHeaders, httpClient, isSucessResponse } from "./http-instance";

export const brokerMPURequest = async (fileName: string) => {
    try {
        const response = await httpClient.post(
            ROUTES.BROKER_MPU_REQUEST,
            {
                data: { fileName },
            },
            getHeaders()
        );
        if (isSucessResponse(response)) {
            return { success: true, data: response };
        } else {
            return { success: false, data: {} as AxiosResponse };
        }
    } catch (error) {
        console.log(error);
        return { success: false, data: {} as AxiosResponse };
    }
};

export const brokerMPUStarted = async (fileName: string) => {
    try {
        const response = await httpClient.post(
            ROUTES.BROKER_MPU_STARTED,
            {
                data: { fileName },
            },

            getHeaders()
        );
        if (isSucessResponse(response)) {
            return { success: true, data: response };
        } else {
            return { success: false, data: {} as AxiosResponse };
        }
    } catch (error) {
        console.log(error);
        return { success: false, data: {} as AxiosResponse };
    }
};

export const brokerMPUCompleted = async (fileNames: string[]) => {
    try {
        const response = await httpClient.post(
            ROUTES.BROKER_MPU_COMPLETED,
            {
                data: { fileNames },
            },

            getHeaders()
        );
        if (isSucessResponse(response)) {
            return { success: true, data: response };
        } else {
            return { success: false, data: {} as AxiosResponse };
        }
    } catch (error) {
        console.log(error);
        return { success: false, data: {} as AxiosResponse };
    }
};
