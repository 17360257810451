import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "src/components/modal";
import { AuthenticationContext } from "src/context/auth";
import { ModalType } from "src/types";

export const Login = () => {
    const { creds, updateEmail, updatePassword, authenticateUser, isLoading } =
        useContext(AuthenticationContext);
    const { email, password } = creds;
    const { openModal, changeModalType } = useContext(ModalContext);
    const [isWrongCredsError, setIsWrongCredsError] = useState(false);

    const handler = async () => {
        const response = await authenticateUser();
        if (!response) {
            changeModalType(ModalType.WrongCrds);
            setTimeout(() => {
                openModal();
            }, 100);
        }
    };

    const isLoginButtonClickable = (): boolean => {
        return email !== '' && password !== '';
      };

    useEffect(() => {
        return () => setIsWrongCredsError(false);
    }, []);
    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <div
            style={{
                backgroundColor: "#116DFF",
                height: "100vh",
                display: "grid",
                gridTemplateColumns: "1fr 0.7fr 1fr",
                gridTemplateRows: "1fr 1fr 1fr",
            }
        }
        >
            <div
                style={{
                    gridColumn: 2,
                    gridRow: "1",
                    minHeight: "400px",
                    paddingTop: "4.2rem",
                    width: "100%",
                    display: "grid",
                    gap: "2rem",
                }}
            >
                <div
                    style={{
                        fontSize: "32px",
                        fontWeight: "800",
                        textAlign: "center",
                        width: "140%",
                        marginLeft: "-20%",
                        color: "white",
                        letterSpacing: "0.5px"
                    }}
                >
                    Jetzt Punktwolken automatisch <br/> in BIM-Modelle umwandeln
                </div>
                <div
                    style={{
                        fontSize: "18px",
                        color: "white",
                        textAlign: "center",
                        fontWeight: "100",
                        letterSpacing: "0.5px"
                    }}
                >
                    Sie müssen angemeldet sein, um auf diese Seite zuzugreifen.{" "}
                </div>
                {isWrongCredsError && (
                    <>
                        <div>"Falsche Einwahldaten"</div>
                        <div>
                            "Bitte geben Sie einen korrekten Usernamen, sowie
                            Passwort an."
                        </div>
                    </>
                )}
                <div
                    style={{
                        display: "grid",
                        backgroundColor: "#418BFF",
                        height: "250px",
                        padding: "1rem 2rem 1rem 2rem",
                        borderRadius: "5px",
                        alignContent: "space-around"
                    }}
                >
                    <TextField
                        id="login-identifier"
                        value={email}
                        onChange={(e) => {
                            updateEmail(e.target.value)
                        }}
                        InputLabelProps={{
                             style: { color: "white" },
                             shrink: true,
                        }}
                        inputProps={{
                            style: { color: "white" },
                            placeholder: 'z.B. muellerm'
                        }}
                        label="Nutzername"
                        variant="standard"
                        sx={{
                            '& .MuiInputLabel-root': {
                            color: 'white',
                            transform: 'none', // To place the label above the input
                            top: 0,
                            marginBottom: '8px'
                            },
                            '& .MuiInputBase-input': {
                            color: 'white',
                            paddingTop: '16px',
                            fontFamily: "Noto Sans",
                            fontWeight: "800"
                            },
                            '& .MuiInputBase-input::placeholder': {
                            color: '#CCCCCC',
                            opacity: 1,
                            fontFamily: "Noto Sans",
                            fontWeight: "800"
                            },
                            '& .MuiInput-underline:before': {
                            borderBottomColor: 'transparent',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'transparent',
                            },
                            '& .MuiInput-underline:after': {
                            borderBottomColor: 'transparent',
                            },
                        }}
                    />
                    <TextField
                        id="login-password"
                        label="Passwort"
                        value={password}
                        InputLabelProps={{
                            style: { color: "white" },
                            shrink: true,
                        }}
                        inputProps={{
                            style: { color: "white" },
                            placeholder: 'Passwort'
                        }}
                        onChange={(e) => updatePassword(e.target.value)}
                        type="password"
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                handler();
                            }
                        }}
                        autoComplete="current-password"
                        variant="standard"
                        sx={{
                            '& .MuiInputLabel-root': {
                            color: 'white',
                            transform: 'none', // To place the label above the input
                            top: 0,
                            marginBottom: '8px'
                            },
                            '& .MuiInputBase-input': {
                            color: 'white',
                            paddingTop: '16px',
                            fontFamily: "Noto Sans",
                            fontWeight: "800"
                            },
                            '& .MuiInputBase-input::placeholder': {
                            color: '#CCCCCC',
                            opacity: 1,
                            fontFamily: "Noto Sans",
                            fontWeight: "800"
                            },
                            '& .MuiInput-underline:before': {
                            borderBottomColor: 'transparent',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'transparent',
                            },
                            '& .MuiInput-underline:after': {
                            borderBottomColor: 'transparent',
                            },
                        }}
                    />
                </div>
                <p
                    style={{
                        fontSize: "18px",
                        color: "white",
                        textAlign: "center",
                        fontWeight: "100",
                        letterSpacing: "0.5px",
                        width: "75%",
                        gridColumn: "1 / 2",
                        marginLeft: "10%",
                        marginTop: "-0.5rem" //reduce the space to the upper element
                    }}
                >
                    Probleme?{" "} <a href="mailto:tech@x.com" style={{ color: "#00B4FF" }} > Hier 
                    </a>{" "} können Sie sich an das Support Team wenden.
                </p>
                <div
                    style={{
                        display: "grid",
                        justifyItems: "right",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <Button
                        onClick={handler}
                        disabled={!isLoginButtonClickable()}
                        style={{
                            fontFamily: "Noto Sans",
                            fontWeight: "800",
                            fontSize: "16px",
                            gridColumn: "2",
                            color: "#116DFF",
                            width: "125px",
                            height: "45px",
                            backgroundColor: isLoginButtonClickable() ? "white" : "#00B4FF",
                            textTransform: "none",
                            marginTop: "-1rem", //reduce the space to the upper element
                        }}
                    >
                        Anmelden
                    </Button>
                </div>
            </div>
        </div>
    );
};
