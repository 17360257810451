import { useContext } from "react";
import { ModalContext } from "src/components/modal";
import { ModalType } from "src/types";

const PageInstructions = () => {
    const { openModal, changeModalType } = useContext(ModalContext);

    return (
        <div style={{
            color: "#002D74CC",
            fontSize: "16px"
        }}
            >
            Dabei bitte die&nbsp;
            <span
                style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 800,
                    opacity: "0.8"
                }}
                onClick={() => {
                    changeModalType(ModalType.Instructions);
                    setTimeout(() => {
                        openModal();
                    }, 100);
                }}
            >
                Hinweise
            </span>
            &nbsp;beachten
        </div>
    );
};

export default PageInstructions;
