import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { ModalType } from "src/types";

export interface ModalState {
    isOpen: boolean;
    closeModal: () => void;
    changeModalType: (i: ModalType) => void;
    openModal: (p?: any) => void;
}

export const ModalContext = React.createContext({} as ModalState);

export const ModalController = ({ children }: any) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState<ModalType>(
        ModalType.Instructions
    );

    const changeModalType = (i: ModalType) => {
        setModalType(i);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const IState: ModalState = {
        isOpen,
        changeModalType,
        closeModal,
        openModal,
    };

    return (
        <ModalContext.Provider value={IState}>
            {children}
            {modalType === ModalType.Instructions && <InstructionModal />}
            {modalType === ModalType.WrongCrds && <WrongCredsModal />}
        </ModalContext.Provider>
    );
};

const StyleModal = {
    display: "grid",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    backgroundColor: "white",
    p: 4,
};

export const InstructionModal = (props: any) => {
    const { isOpen, closeModal } = React.useContext(ModalContext);

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={StyleModal}>
                <Typography
                    style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#002D74",
                    }}
                    variant="h6"
                    component="h4"
                >
                    Bedienungshinweise
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    1.{" "}
                    <span style={{ fontWeight: 500, color: "#4C6C9E" }}>
                        Akzeptiertes Uploadformat:
                    </span>{" "}
                    .LAZ (max. 15GB)
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    <span style={{ fontWeight: 500, color: "#4C6C9E" }}>
                        2. Verfügbare Downloadformate:
                    </span>{" "}
                    .DXF (CAD-Zeichnung), .IFC (BIM-Datei)
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    3.{" "}
                    <span style={{ fontWeight: 500, color: "#4C6C9E" }}>
                        Dauer:
                    </span>{" "}
                    Nach erfolgreichem Upload wird die Verarbeitung gestartet.
                    Diese kann je nach Datei-Größe und Aufbau 0,5 - 3h dauern.
                    Nach erfolgreicher Verarbeitung wird per Mail ein
                    Download-Link verschickt.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    4.{" "}
                    <span style={{ fontWeight: 500, color: "#4C6C9E" }}>
                        Status:
                    </span>{" "}
                    Es gibt keine Übersicht der laufenden Jobs. Nach
                    erfolgreichem Upload und erfolgreicher Verarbeitung werden
                    Mails verschickt. Einzelne Dateien bitte nicht mehrfach
                    hochladen, sondern auf die Mail warten.
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                    5.{" "}
                    <span style={{ fontWeight: 500, color: "#4C6C9E" }}>
                        Probleme:
                    </span>{" "}
                    Bei Problemen bitte eine Mail an tech@roometric.com
                    schicken.
                </Typography>
                <div style={{ display: "grid" }}>
                    <Button
                        onClick={closeModal}
                        style={{
                            height: "60px",
                            width: "200px",
                            color: "#00AA44",
                            backgroundColor: "#E6F7ED",
                            justifySelf: "end",
                        }}
                    >
                        Verstanden
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export const WrongCredsModal = (props: any) => {
    const { isOpen, closeModal } = React.useContext(ModalContext);

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={StyleModal}>
                <Typography 
                        style={{textAlign: "center"}}

                 variant="h6" component="h4">
                    Falsche Einwahldaten
                </Typography>
                <Typography 

                        style={{textAlign: "center"}}

                 variant="h6" component="h4">
                    Bitte geben Sie einen korrekten Usernamen, sowie Passwort
                    an.
                </Typography>
                <div style={{ display: "grid" }}>
                    <Button
                        onClick={closeModal}
                        style={{
                            height: "60px",
                            width: "200px",
                            color: "#00AA44",
                            backgroundColor: "#E6F7ED",
                            justifySelf: "end",
                        }}
                    >
                        Ok
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};
