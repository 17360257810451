import { CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "src/screens/Auth";
import FileUpload from "src/screens/FileUpload";
import { AuthenticationContext } from "./context/auth";

const Loading = () => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            <div style={{ gridColumn: 2, justifySelf: "center", paddingTop: "12em" }}>
                <CircularProgress />
            </div>
        </div>
    );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated, isLoading } = useContext(AuthenticationContext);
    const location = useLocation();

    if (isLoading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const RequireNotAuth = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated, isLoading } = useContext(AuthenticationContext);
    const location = useLocation();
    if (isLoading) {
        return <Loading />;
    }

    if (isAuthenticated) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
};

const Router = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <FileUpload />
                    </RequireAuth>
                }
            />
            <Route
                path="/login"
                element={
                    <RequireNotAuth>
                        <Login />
                    </RequireNotAuth>
                }
            />
        </Routes>
    );
};

export default Router;
