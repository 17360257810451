import { ROUTES } from "src/consts";
import { getHeaders, httpClient, isSucessResponse } from "./http-instance";

// Verify user token from local storage
export const verifyUser = async (token: string): Promise<boolean> => {
    try {
        const response = await httpClient.post(
            ROUTES.USER_VERIFY,
            {},
            getHeaders()
        );

        if (isSucessResponse(response)) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
};

// main signin api request
export const signin = async (
    email: string,
    password: string
): Promise<{ success: boolean; data: string | null }> => {
    try {
        const data = {
            data: { email, password },
        };
        const response = await httpClient.post(ROUTES.USER_SIGN, data);
        const isSuccess = isSucessResponse(response);
        if (isSuccess) {
            return { success: true, data: response.data.token };
        }
        return { success: false, data: null };
    } catch (error) {
        return { success: false, data: null };
    }
};
