import * as React from "react";
import { ModalController } from "./components/modal";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthenticationProvider } from "./context/auth";
import { FileUploadProvider } from "./context/fileUpload";

function init() {
    const rootElement = document.getElementById("root");
    if (!rootElement) {
        return;
    } else {
        const root = createRoot(rootElement);
        root.render(
            <AuthenticationProvider>
                <BrowserRouter>
                    <ModalController>
                        <FileUploadProvider>
                            <App />
                        </FileUploadProvider>
                    </ModalController>
                </BrowserRouter>
            </AuthenticationProvider>
        );
    }
}

init();
