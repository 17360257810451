import Axios, { AxiosResponse } from "axios";
import { getToken } from "src/localstorage";

export const getHeaders = () => {
    return {
        headers: {
            authorization: "Bearer " + getToken(),
        },
    };
};

export const httpClient = Axios;

export const isSucessResponse = (r: AxiosResponse): boolean =>
    r.status < 300 && r.status > 199;
